<template>
    <div>
        <span v-if='downloading' class="flex justify-center">
            <svg class="animate-spin h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </span>
        <a v-else class="flex justify-center cursor-pointer" @click="downloadFile(data.document_number, data)">
            <!-- <span class="text-xl cursor-pointer icon-color iconly-brokenDownload"></span> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            <!-- <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg> -->
        </a>
    </div>
</template>

<script>
    import download from 'downloadjs'

    export default {
        name : 'download-manager',
        props : {
            data : {
                type : Object,
                required : true
            },
            value : {
                type : String,
                required : true
            }
        },
        data () {
            return {
                downloading : false
            }
        },
        mounted () {

        },
        methods : {
            downloadFile (DocumentNumber, data = null) {
                this.downloading = true
                const payload = {}

                if (this.value === 'billing') {
                    payload.key = this.value
                    payload.doc = DocumentNumber
                } else {
                    payload.key = this.value
                    payload.doc = DocumentNumber
                    payload.id = this.data
                }

                this.downloading = true
                this.$store.dispatch('portal/downloadFile', payload)
                .then(res => {
                    // console.log('d', res)
                    this.downloading = false
                    const content = res.headers['content-type']
                    download(res.data, `${this.value}_${DocumentNumber}_invoice.pdf`, content)
                }).catch(err => {
                    this.downloading = false
                    if (err.response.status === 401) {
                        // this.$store.commit('CLEAR_AUTH_USER', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
